import { useIntl, Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"

import Hatena from "./hatenaBookmark"
import Adsense from "./adsense"
import BuyMeACoffee from "./buymeacoffee"

const Sidebar = () => {
  const intl = useIntl()

  return (
    <aside className="hidden md:block md:w-1/4">
      <div className="fixed h-full w-56 lg:w-64">
        <div className="h-64 mb-3 p-3 text-sm bg-white rounded border border-gray-400">
          <p className="font-bold text-lg">Welcome!</p>
          <div className="pt-3">{intl.formatMessage({ id: "side.about" })}</div>
          <div className="pt-3 font-semibold">
            <Link
              to="/info/terms/"
              activeClassName="hover:underline"
              className="hover:underline"
            >
              {intl.formatMessage({ id: "side.check terms" })}
            </Link>
          </div>
          <div className="pt-6">
            <div className="mb-3">
              <span className="font-bold text-lg">Follow Me</span>
            </div>
            <div className="flex">
              <a href="https://twitter.com/flatsvgdesigns" className="mr-2">
                <img
                  src="/images/Twitter_Logo_WhiteOnBlue.svg"
                  alt="twitter icon"
                  className="rounded w-8 h-auto hover:opacity-75"
                  loading="lazy"
                ></img>
              </a>
              <Hatena />
            </div>
          </div>
        </div>
        <BuyMeACoffee />
        <div className="h-64">
          <Adsense />
        </div>
      </div>
    </aside>
  )
}

Sidebar.propTypes = {
  siteTitle: PropTypes.string,
}

Sidebar.defaultProps = {
  siteTitle: ``,
}

export default Sidebar
