import { useIntl, Link } from "gatsby-plugin-intl"
import React, { useState } from "react"

import Language from "./language"
import SpSideNav from "./spSideNav"

const Header = () => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)

  const useInput = initialValue => {
    const [value, set] = useState(initialValue)
    return {
      value,
      onChange: e => {
        set(e.target.value)
      },
    }
  }

  const searchWord = useInput("")

  function triggerSearch(e) {
    if (e.keyCode === 13) {
      window.location = "/query?q=" + searchWord.value
    }
  }

  let hamburger = ""
  // ハンバーガー画像を入れ替える
  if (isOpen) {
    hamburger = (
      <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
        />
      </svg>
    )
  } else {
    hamburger = (
      <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
        />
      </svg>
    )
  }

  return (
    <nav className="bg-white fixed w-full z-40 top-0 border-b border-gray-400">
      <div className="max-w-5xl mx-auto flex content-center flex-wrap items-center justify-between h-12">
        <div className="flex flex-shrink justify-center md:justify-start">
          <Link to="/">
            <div className="pl-3 font-bold text-2xl">FLAT SVG DESIGNS</div>
          </Link>
        </div>
        <div className="hidden md:flex flex-grow justify-center md:justify-end">
          <span className="relative w-128">
            <input
              type="search"
              placeholder="Search"
              className="w-full bg-gray-200 text-sm text-gray-700 transition border border-gray-400 focus:outline-none focus:border-gray-500 rounded py-1 px-2 pl-8 appearance-none leading-normal"
              onKeyDown={e => triggerSearch(e)}
              {...searchWord}
            />
            <div className="absolute top-0 mt-2 ml-2">
              <svg
                className="fill-current pointer-events-none text-gray-700 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
              </svg>
            </div>
          </span>
        </div>
        <div className="hidden md:flex w-36 text-sm text-gray-700 content-center justify-end visible p-1 mx-2">
          <Language />
        </div>
        <div className="flex content-center justify-end visible md:hidden mx-3">
          <button
            type="button"
            className="block text-gray-700 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
            onClick={() => setIsOpen(!isOpen)}
          >
            {hamburger}
          </button>
          <div
            className={
              isOpen
                ? "w-2/3 block absolute bg-gray-800 text-white right-0 mt-8 p-3 overflow-auto z-30 rounded rounded-r-none"
                : "w-2/3 hidden absolute bg-gray-800 text-white right-0 mt-8 p-3 overflow-auto z-30 rounded rounded-r-none"
            }
          >
            <SpSideNav />
          </div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto py-3 overflow-y-auto whitespace-no-wrap scroll-hidden">
        <Link
          to="/icons/"
          activeClassName="inline-flex items-center justify-center text-sm text-gray-700 font-bold underline leading-5 p-1 rounded mx-3 hover:bg-gray-200"
          className="inline-flex items-center justify-center text-sm text-gray-700 leading-5 p-1 rounded mx-3 hover:bg-gray-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="fill-current mr-1"
          >
            <path d="M5 3h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 2v4h4V5H5zm10-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 2v4h4V5h-4zM5 13h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4c0-1.1.9-2 2-2zm0 2v4h4v-4H5zm10-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-4c0-1.1.9-2 2-2zm0 2v4h4v-4h-4z" />
          </svg>
          <span>{intl.formatMessage({ id: "category.icons" })}</span>
        </Link>
        <Link
          to="/kung-fu-geek/"
          activeClassName="inline-flex items-center justify-center text-sm text-gray-700 font-bold underline leading-5 p-1 rounded mx-3 hover:bg-gray-200"
          className="inline-flex items-center justify-center text-sm text-gray-700 leading-5 p-1 rounded mx-3 hover:bg-gray-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="fill-current mr-1"
          >
            <path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 8.59V6H4v6.59l4.3-4.3a1 1 0 0 1 1.4 0l5.3 5.3 2.3-2.3a1 1 0 0 1 1.4 0l1.3 1.3zm0 2.82l-2-2-2.3 2.3a1 1 0 0 1-1.4 0L9 10.4l-5 5V18h16v-2.59zM15 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
          {intl.formatMessage({ id: "category.kfg" })}
        </Link>
        <Link
          to="/illustrations/"
          activeClassName="inline-flex items-center justify-center text-sm text-gray-700 font-bold underline leading-5 p-1 rounded mx-3 hover:bg-gray-200"
          className="inline-flex items-center justify-center text-sm text-gray-700 leading-5 p-1 rounded mx-3 hover:bg-gray-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="fill-current mr-1"
          >
            <path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 8.59V6H4v6.59l4.3-4.3a1 1 0 0 1 1.4 0l5.3 5.3 2.3-2.3a1 1 0 0 1 1.4 0l1.3 1.3zm0 2.82l-2-2-2.3 2.3a1 1 0 0 1-1.4 0L9 10.4l-5 5V18h16v-2.59zM15 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
          {intl.formatMessage({ id: "category.illusts" })}
        </Link>
        {/* <Link
          to="/animations/"
          activeClassName="text-sm text-gray-700 font-bold underline leading-5 p-2 rounded mx-3 hover:bg-gray-200"
          className="text-sm text-gray-700 leading-5 p-2 rounded mx-3 hover:bg-gray-200"
        >
          ✨{intl.formatMessage({ id: "category.animes" })}
        </Link> */}
        <Link
          to="/tools/"
          activeClassName="inline-flex items-center justify-center text-sm text-gray-700 font-bold underline leading-5 p-1 rounded mx-3 hover:bg-gray-200"
          className="inline-flex items-center justify-center text-sm text-gray-700 leading-5 p-1 rounded mx-3 hover:bg-gray-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="fill-current mr-1"
          >
            <path d="M8 7V5c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h4zm8 2H8v10h8V9zm2 0v10h2V9h-2zM6 9H4v10h2V9zm4-2h4V5h-4v2z" />
          </svg>
          <span>{intl.formatMessage({ id: "category.tools" })}</span>
        </Link>
        {/* <Link
          to="/refferences/"
          activeClassName="text-sm text-gray-700 font-bold underline leading-5 p-2 rounded mx-3 hover:bg-gray-200"
          className="text-sm text-gray-700 leading-5 p-2 rounded mx-3 hover:bg-gray-200"
        >
          📖{intl.formatMessage({ id: "category.refs" })}
        </Link> */}
      </div>
    </nav>
  )
}

export default Header
