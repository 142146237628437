import React, { useEffect } from "react"

const Adsense = () => {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  }, [])

  return (
    <div>
      {/* ディスプレイ広告 */}
      {/* <!-- flat-svg-designs-256-256 --> */}
      <ins
        className="adsbygoogle"
        style={{ display: "inline-block", width: 256, height: 256 }}
        data-ad-client="ca-pub-5843269452604762"
        data-ad-slot="1189955873"
      ></ins>
    </div>
  )
}

export default Adsense
