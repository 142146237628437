/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import "../utils/tailwind.css"
import "./layout.css"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import Sidebar from "./sidebar"

import "typeface-lato"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-DRX889SS4D"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-DRX889SS4D');
          `}
        </script>
      </Helmet>
      <div className="text-gray-800 min-h-screen relative bg-gray-200">
        <Header siteTitle={data.site.siteMetadata.title || `Title`} />
        {/* Main Content  */}
        <div className="wrapper main-content md:container md:mx-auto flex-1 mt-24 pt-3">
          <div className="max-w-5xl flex flex-wrap mx-auto content-center self-center justify-center">
            {children}
            <Sidebar />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
