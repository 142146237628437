import React from "react"

const buymeacoffee = () => {
  return (
    <div className="w-full flex justify-center mb-3">
      <a href="https://www.buymeacoffee.com/seuyubin" target="_blank">
        <img
          src="https://cdn.buymeacoffee.com/buttons/v2/default-orange.png"
          alt="Buy Me A Coffee"
          style={{ height: 45, width: 162 }}
        ></img>
      </a>
    </div>
  )
}

export default buymeacoffee
