import React from "react"

const Hatena = () => {
  const hatenaUrl = "http://b.hatena.ne.jp/add?mode=confirm&url="

  function jump2hatena() {
    const href =
      hatenaUrl + window.location.href + "&title=" + encodeURI(document.title)
    window.open(
      href,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
    )
  }

  return (
    <div className="mr-2 bg-white rounded-lg">
      <img
        src="/images/hatenabookmark.svg"
        alt="hatenabookmark icon"
        className="rounded w-8 h-auto hover:opacity-75"
        loading="lazy"
        onClick={() => jump2hatena()}
      ></img>
    </div>
  )
}

export default Hatena
