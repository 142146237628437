import React, { useState } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import Language from "./language"
import Hatena from "./hatenaBookmark"
import BuyMeACoffee from "./buymeacoffee"

const SpSideNav = () => {
  const intl = useIntl()

  const useInput = initialValue => {
    const [value, set] = useState(initialValue)
    return {
      value,
      onChange: e => {
        set(e.target.value)
      },
    }
  }

  const searchWord = useInput("")

  function triggerSearch(e) {
    if (e.keyCode === 13) {
      window.location = "/query?q=" + searchWord.value
    }
  }

  return (
    <div>
      <input
        type="text"
        className="w-full p-2 text-gray-600 appearance-none"
        placeholder="Search.."
        onKeyDown={e => triggerSearch(e)}
        {...searchWord}
      />
      <div className="flex text-sm text-gray-200 justify-around mx-3 mt-6">
        <Language />
      </div>
      <div className="border-b-2 border-gray-700 pb-3"></div>
      <Link to="/">
        <div className="inline-flex items-center w-full p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="fill-current mr-2"
            width="16"
            height="16"
          >
            <path d="M13 20v-5h-2v5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-7.59l-.3.3a1 1 0 1 1-1.4-1.42l9-9a1 1 0 0 1 1.4 0l9 9a1 1 0 0 1-1.4 1.42l-.3-.3V20a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2zm5 0v-9.59l-6-6-6 6V20h3v-5c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v5h3z" />
          </svg>
          <span>{intl.formatMessage({ id: "category.home" })}</span>
        </div>
      </Link>
      <Link to="/icons/">
        <div className="inline-flex items-center w-full p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="fill-current mr-2"
          >
            <path d="M5 3h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 2v4h4V5H5zm10-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 2v4h4V5h-4zM5 13h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4c0-1.1.9-2 2-2zm0 2v4h4v-4H5zm10-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-4c0-1.1.9-2 2-2zm0 2v4h4v-4h-4z" />
          </svg>
          <span>{intl.formatMessage({ id: "category.icons" })}</span>
        </div>
      </Link>
      <Link to="/kung-fu-geek/">
        <div className="inline-flex items-center w-full p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="fill-current mr-2"
          >
            <path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 8.59V6H4v6.59l4.3-4.3a1 1 0 0 1 1.4 0l5.3 5.3 2.3-2.3a1 1 0 0 1 1.4 0l1.3 1.3zm0 2.82l-2-2-2.3 2.3a1 1 0 0 1-1.4 0L9 10.4l-5 5V18h16v-2.59zM15 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
          <span>{intl.formatMessage({ id: "category.kfg" })}</span>
        </div>
      </Link>
      <Link to="/illustrations/">
        <div className="inline-flex items-center w-full p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="fill-current mr-2"
          >
            <path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 8.59V6H4v6.59l4.3-4.3a1 1 0 0 1 1.4 0l5.3 5.3 2.3-2.3a1 1 0 0 1 1.4 0l1.3 1.3zm0 2.82l-2-2-2.3 2.3a1 1 0 0 1-1.4 0L9 10.4l-5 5V18h16v-2.59zM15 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
          <span>{intl.formatMessage({ id: "category.illusts" })}</span>
        </div>
      </Link>
      {/* <Link to="/animations/">
        <div className="p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          <span role="img" aria-label="animations">
            ✨
          </span>
          {intl.formatMessage({ id: "category.animes" })}
        </div>
      </Link> */}
      <Link to="/tools/">
        <div className="inline-flex items-center w-full p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            className="fill-current mr-2"
          >
            <path d="M8 7V5c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h4zm8 2H8v10h8V9zm2 0v10h2V9h-2zM6 9H4v10h2V9zm4-2h4V5h-4v2z" />
          </svg>
          <span>{intl.formatMessage({ id: "category.tools" })}</span>
        </div>
      </Link>
      {/* <Link to="/reffereces/">
        <div className="p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          <span role="img" aria-label="refferences">
            📖
          </span>
          {intl.formatMessage({ id: "category.refs" })}
        </div>
      </Link> */}
      <div className="border-b-2 border-gray-700 pb-3"></div>
      <div className="flex mt-3 ml-2">
        <a
          href="https://twitter.com/flatsvgdesigns"
          className="mr-2 bg-white rounded-lg"
        >
          <img
            src="/images/Twitter_Logo_WhiteOnBlue.svg"
            alt="twitter icon"
            className="rounded w-8 h-auto hover:opacity-75"
            loading="lazy"
          ></img>
        </a>
        <Hatena />
      </div>
      <div className="border-b-2 border-gray-700 pb-3"></div>
      <div className="mt-3">
        <BuyMeACoffee />
      </div>
      <div className="border-b-2 border-gray-700 pb-3"></div>
      <Link to="/info/about/">
        <div className="p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          {intl.formatMessage({ id: "category.about" })}
        </div>
      </Link>
      <Link to="/info/terms/">
        <div className="p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          {intl.formatMessage({ id: "category.terms" })}
        </div>
      </Link>
      <Link to="/info/contact/">
        <div className="p-2 hover:bg-gray-700 text-white text-sm no-underline hover:no-underline block">
          {intl.formatMessage({ id: "category.contact" })}
        </div>
      </Link>
    </div>
  )
}
export default SpSideNav
