import { useIntl, Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"

const Header = () => {
  const intl = useIntl()
  return (
    <footer className="w-full border-t border-gray-400">
      <div className="w-full container mx-auto flex flex-col items-center">
        <div className="hidden md:flex pt-2 text-xs flex-wrap">
          <div className="mx-3">
            <Link to="/info/about/">
              {intl.formatMessage({ id: "category.about" })}
            </Link>
          </div>
          <div className="mx-3">
            <Link to="/info/terms">
              {intl.formatMessage({ id: "category.terms" })}
            </Link>
          </div>
          <div className="mx-3">
            <Link to="/info/contact">
              {intl.formatMessage({ id: "category.contact" })}
            </Link>
          </div>
        </div>
        <div className="py-2 text-xs">&copy;2020 FLAT SVG DESIGNS</div>
      </div>
    </footer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
